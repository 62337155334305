<template>
  <div class="stylist-card">
    <popper :visible-arrow="false"
            class="action-menu">
      <div class="popper menu">
        <router-link class="menu-link"
                     :to="{ name: 'admin.stylists.profile', params: { id: stylist.id } }">
          {{ $t('admin.stylists.list.profile') }}
        </router-link>
      </div>

      <div class="reference"
           slot="reference">
        <base-icon icon="dotsVertical"></base-icon>
      </div>
    </popper>

    <base-avatar :user="stylist"
                 size="big"/>

    <div class="info">
      <div class="info-field-nickname info-field">
        {{ stylist.nickName }}
      </div>
      <div class="info-field-additional info-field">
        {{ stylist.fullName }}
      </div>
      <div class="info-field-additional info-field">
        {{ stylist.email }}
      </div>
      <div class="info-field-additional info-field">
        {{ stylist.phone_number }}
      </div>
      <div class="info-field-additional info-field"
           v-if="stylist.country">
        {{ stylist.country.title }}
      </div>
      <div class="info-field-additional info-field"
           v-if="timeZone">
        ({{ timeZone.utcOffset }}) {{ timeZone.title }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      stylist: {
        required: true,
        type: Object
      }
    },

    data() {
      return {
        timeZone: null
      }
    },

    created() {
      this.setTimeZone()
    },

    methods: {
      setTimeZone() {
        if (!this.stylist.timezone_id) {
          return
        }

        this.timeZone = this.getTimeZoneById(this.stylist.timezone_id)
      },
    },

    computed: {
      ...mapGetters('handbooks', [
        'getTimeZoneById'
      ])
    }
  }
</script>

<style lang="scss" scoped>
  .stylist-card {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 240px;
    width: 280px;
    height: 350px;

    background: #FFFFFF;
    box-shadow: 0 6px 10px 0 rgba(240,240,240,0.50);
    border-radius: 6px;

    padding: 30px;
    margin: 10px;

    .action-menu {
      .menu {
        display: flex;
        flex-direction: column;
        padding: 16px;
      }

      .menu-link {
        color: #727272;
        text-decoration: none;
        font-size: 16px;
        padding: 10px;

        &:hover, &.active {
          color: $primary-color;
        }
      }

      .reference {
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .icon {
        fill: #797979;
      }

      &:hover .icon {
        fill: $primary-color;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-top: 20px;

      .info-field {
        text-align: center;
        margin-bottom: 10px;
      }

      .info-field-nickname {
        font-size: 16px;
        color: #1C1C1C;
      }

      .info-field-additional {
        font-size: 14px;
        color: #797979;
      }
    }
  }
</style>
