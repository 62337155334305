<template>
  <div class="stylists">
    <div class="top-panel">
      <div class="heading-accent">
        {{ $t('admin.stylists.list.title') }}
      </div>
      <base-button @click="goToNewStylist">
        {{ $t('admin.stylists.list.add') }}
      </base-button>
    </div>

    <div class="stylist-list">
      <div class="stylist-card-wrapper"
          v-for="stylist in stylists"
          :key="stylist.id">
        <stylists-card :stylist="stylist"
                      @update="loadStylists"/>
      </div>
    </div>
  </div>
</template>

<script>
  import profileService from '@/services/queries/profileQueries'
  import StylistsCard from './StylistsCard.vue'

  export default {
    components: {
      StylistsCard
    },

    data() {
      return {
        stylists: [],
      }
    },

    metaInfo() {
      return {
        title: this.$t('admin.stylists.list.pageTitle')
      }
    },

    created() {
      this.loadStylists()
    },

    methods: {
      loadStylists() {
        let params = {
          sorting: 'time_created{desc}',
          user_type: 'stylist'
        }

        profileService.get(params).then(data => {
          this.stylists = data.items
        })
      },

      goToNewStylist() {
        this.$router.push({ name: 'admin.stylists.new' })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .stylists {
    background-color: #F8F8F8;

    .top-panel {
      display: flex;
      justify-content: space-between;
      padding: 20px;
    }

    .stylist-list {
      padding: 0 10px;

      .stylist-card-wrapper {
        display: inline-block;
      }
    }
  }
</style>